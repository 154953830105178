@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: "Anton";
  src: local("Anton"), url(./assets/anton-regular.ttf) format("truetype");
}

body {
  background-color: #443734;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.light-cell {
  background: linear-gradient(
    180deg,
    #f4f4f4 0%,
    #e4cac9 0.01%,
    #ffffff 99.98%,
    #ffffff 99.99%
  );
  box-shadow: inset 1px 1px 1px #000000;
}

.dark-cell {
  background: linear-gradient(
      180deg,
      rgba(128, 3, 3, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #492a2a;
  box-shadow: inset -2px -2px 1px rgba(0, 0, 0, 0.38),
    inset 2px 2px 1px rgba(255, 255, 255, 0.26);
}

.button {
  background: #f91717;
  border-radius: 8px;
  color: #ffffff;
  font-family: Anton;
  text-transform: uppercase;
  padding: 8px 16px;
  font-size: 18px;
  border: none;
}

.plain-button {
  background: none;
  border: none;
  font-family: Anton;
}
